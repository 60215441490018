import React from 'react';
import { Spin } from 'antd';
import cx from 'classnames';
import './styles.scss';

export const LoadingPage: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <div className={cx(className || 'loading-component absolute top-0 left-0 w-full h-full z-0')}>
      <div className={cx('relative h-full', { 'md:ml-[22.5vw]': !className })}>
        <span className='absolute top-1/2 left-1/2 -translate-x-[24px] -translate-y-[24px]'>
          <Spin />
        </span>
      </div>
    </div>
  );
};
