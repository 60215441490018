import { Layout } from 'antd';
import React, { Dispatch, SetStateAction } from 'react';
import { ThemesMode } from '../PrivateLayout';
import { storage } from 'src/utils/storage';
import { STORAGE_KEY } from 'src/constants/storage';
import IconLogout from 'src/assets/icons/common/log-out.svg';
import IconAlert from 'src/assets/icons/common/notification.svg';
import IconToogle from 'src/assets/icons/svg/toogle.svg';
import { useAuth } from 'src/contexts/auth';
import './styles.scss';

const { Header } = Layout;

interface Props {
  collapsed: boolean;
  setCollapsed: (value: boolean) => void;
  theme: ThemesMode;
  setTheme: Dispatch<SetStateAction<ThemesMode>>;
}

const AppHeader: React.FC<Props> = ({ collapsed, setCollapsed, theme, setTheme }) => {
  const { setToken } = useAuth();

  const handleLogout = () => {
    setToken(null);
    storage.remove(STORAGE_KEY.ACCESS_TOKEN);
  };

  return (
    <React.Fragment>
      <Header className='site-layout-header flex items-center bg-white px-8 z-20'>
        <span className='text-white cursor-pointer' onClick={() => setCollapsed(!collapsed)}>
          <img width={18} className='text-white' src={IconToogle} alt='' />
        </span>
        <div className='flex items-center'>
          <span className='cursor-pointer font-bold' onClick={handleLogout}>
            <img className='inline-block mr-2' src={IconLogout} alt='logout' /> Log out
          </span>
        </div>
      </Header>
    </React.Fragment>
  );
};

export default React.memo(AppHeader);
