import { SET_LIST_DEPOSIT } from '../constants';

const initialState = {
  listDeposit: {},
};

export const depositReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_LIST_DEPOSIT: {
      return {
        ...state,
        listDeposit: action.payload.data,
      };
    }

    default:
      return state;
  }
};
