export const PATHS = {
  HOME: '/',
  DASHBOARD: '/dashboard',
  PAGE_404: '/404',
  DASHBOARD_REQUEST_LOGIN: '/dashboard/request-login',
  LOGIN: '/login',

  USERS: '/users',
  USERS_INVESTOR: '/users/investor',
  USERS_AGENT: '/users/agent',
  USERS_MASTER_AGENT: '/users/master-agent',
  USERS_DETAIL: '/user/:id',
  USERS_DETAIL_ID: (id: any) => `/user/${id}`,

  DEPOSITS: '/deposits',
  DEPOSITS_DETAIL: '/deposit/:id',
  DEPOSITS_DETAIL_ID: (id: any) => `/deposit/${id}`,

  WITHDRAWALS: '/withdrawals',
  WITHDRAWALS_DETAIL: '/withdrawal/:id',
  WITHDRAWALS_DETAIL_ID: (id: any) => `/withdrawal/${id}`,

  PROJECTS: '/projects',
  PROJECTS_CREATE: '/project/create',
  PROJECTS_DETAIL: '/project/:id',
  PROJECTS_DETAIL_ID: (id: any) => `/project/${id}`,
  PROJECTS_UPDATE: '/project/update/:id',
  PROJECTS_UPDATE_ID: (id: any) => `/project/update/${id}`,

  PROJECTS_CONTRACT_DETAIL: '/project/:projectId/contract/:contractId',
  PROJECTS_CONTRACT_DETAIL_ID: (projectId: number, contractId: number) =>
    `/project/${projectId}/contract/${contractId}`,
  SETTING: '/setting',
  WALLET: '/wallet',
  CONTRACT_CANCELATION: '/contract-cancellation',
};
