import { combineReducers } from 'redux';
import { depositReducer } from './reducers/deposit';
import { projectReducer } from './reducers/project';
import { userReducer } from './reducers/user';
import { withdrawalReducer } from './reducers/withdrawal';
import { authReducer } from './reducers/access';

const appReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  deposit: depositReducer,
  withdrawal: withdrawalReducer,
  project: projectReducer,
});

export const rootReducer = (state: any, action: any) => appReducer(state, action);
