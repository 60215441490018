import { SET_DETAIL_PROJECT, SET_LIST_PROJECT } from '../constants';

const initialState = {
  listProject: {},
  detailProject: {},
};

export const projectReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_LIST_PROJECT: {
      return {
        ...state,
        listProject: action.payload.data,
      };
    }

    case SET_DETAIL_PROJECT: {
      return {
        ...state,
        detailProject: action.payload.data,
      };
    }

    default:
      return state;
  }
};
