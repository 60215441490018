import { SET_LIST_WITHDRAWAL } from '../constants';

const initialState = {
  listWithdrawal: {},
};

export const withdrawalReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_LIST_WITHDRAWAL: {
      return {
        ...state,
        listWithdrawal: action.payload.data,
      };
    }

    default:
      return state;
  }
};
