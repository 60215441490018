import React, { Fragment } from 'react';
import { Layout, Menu } from 'antd';
import cx from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import { PATHS } from 'src/constants/paths';
import { SidebarList } from './constants/SidebarList';
import LOGO from 'src/assets/images/logo.png';
import SubMenu from 'antd/lib/menu/SubMenu';
import './styles.scss';

const { Item } = Menu;
interface ISidebar {
  collapsed: boolean;
}

const Sidebar: React.FC<ISidebar> = ({ collapsed }) => {
  const { Sider } = Layout;
  const history = useHistory();
  const location = useLocation();
  const selectedKey = `/${location.pathname.split('/')[1]}`;
  const backToHome = () => history.push(PATHS.HOME);

  return (
    <Sider collapsed={collapsed} theme={'light'} className='sider' width={256}>
      <div className='logo-section text-center font-bold text-xl cursor-pointer' onClick={backToHome}>
        <img width={130} className='mx-auto' src={LOGO} alt='logo' />
      </div>
      <Menu theme='light' selectedKeys={[selectedKey]} mode='inline' className='main-menu py-4'>
        {SidebarList.map((item: any, index: number) => {
          return (
            <Fragment key={index}>
              {!!item.inMenu && (
                <Item
                  key={item.subLink}
                  onClick={() => history.push(item.subLink)}
                  icon={<img src={item.icon} className='mr-1' alt='' />}
                >
                  {!collapsed && item.label}
                </Item>
              )}
              {item?.children && (
                <SubMenu
                  key={`${item.subLink}_${index}`}
                  icon={<img className='inline-block' src={item.icon} style={{ marginRight: '14px' }} alt='' />}
                  title={!collapsed && item.label}
                  className='sub-menu-container'
                >
                  {item.children.map((subItem: any) => {
                    return (
                      <Menu.Item
                        key={subItem.subLink}
                        onClick={() => history.push(subItem.subLink)}
                        className={cx('sub-menu-container__item', { active: subItem.subLink === location?.pathname })}
                      >
                        {subItem.label}
                      </Menu.Item>
                    );
                  })}
                </SubMenu>
              )}
            </Fragment>
          );
        })}
      </Menu>
    </Sider>
  );
};

export default Sidebar;
