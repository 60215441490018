import React from 'react';
import ACCOUNT_ICON from 'src/assets/icons/sidebar/accounts.svg';
import DASHBOARD_ICON from 'src/assets/icons/sidebar/dashboard.svg';
import DEPOSIT_ICON from 'src/assets/icons/sidebar/deposit.svg';
import PROJECT_ICON from 'src/assets/icons/sidebar/project.svg';
import WITHDRAWAL_ICON from 'src/assets/icons/sidebar/withdraw.svg';
import WALLET_ICON from 'src/assets/icons/sidebar/wallet-svgrepo-com.svg';
import GEAR from 'src/assets/icons/sidebar/gear.svg';
import { PATHS } from 'src/constants/paths';

const DashboardComponent = React.lazy(() => import('src/pages/dashboard'));
const RequestLoginComponent = React.lazy(() => import('src/pages/dashboard/request-login'));
const UsersComponent = React.lazy(() => import('src/pages/users'));
const UsersDetailComponent = React.lazy(() => import('src/pages/users/detail'));
const DepositsComponent = React.lazy(() => import('src/pages/deposits'));
const DepositsDetailComponent = React.lazy(() => import('src/pages/deposits/detail'));
const WithdrawalsComponent = React.lazy(() => import('src/pages/withdrawals'));
const WithdrawalsDetailComponent = React.lazy(() => import('src/pages/withdrawals/detail'));
const ProjectComponent = React.lazy(() => import('src/pages/project'));
const CreateProjectComponent = React.lazy(() => import('src/pages/project/create'));
const ProjectDetailComponent = React.lazy(() => import('src/pages/project/detail'));
const ProjectContractDetailComponent = React.lazy(() => import('src/pages/project/detail/contract/detail'));
const LoginComponent = React.lazy(() => import('src/pages/login'));
const NotFoundComponent = React.lazy(() => import('src/routes/components/NotFound'));
const SettingComponent = React.lazy(() => import('src/pages/setting'));
const WalletComponent = React.lazy(() => import('src/pages/wallet'));
const ContractCancellation = React.lazy(() => import('src/pages/contract-cancellation'));

export const SidebarList = [
  {
    label: 'Homepage',
    subLink: PATHS.HOME,
    component: DashboardComponent,
    exact: true,
    inMenu: false,
    icon: ACCOUNT_ICON,
  },
  {
    label: 'Dashboard',
    subLink: PATHS.DASHBOARD,
    component: DashboardComponent,
    exact: true,
    inMenu: true,
    icon: DASHBOARD_ICON,
  },
  {
    label: 'Dashboard',
    subLink: PATHS.DASHBOARD_REQUEST_LOGIN,
    component: RequestLoginComponent,
    exact: true,
  },
  {
    label: 'Users',
    subLink: PATHS.USERS,
    component: UsersComponent,
    exact: true,
    icon: ACCOUNT_ICON,
    children: [
      {
        label: 'Investor',
        subLink: PATHS.USERS_INVESTOR,
        component: UsersComponent,
        exact: true,
      },
      {
        label: 'Agent',
        subLink: PATHS.USERS_AGENT,
        component: UsersComponent,
        exact: true,
      },
      {
        label: 'Agency (Master Agent)',
        subLink: PATHS.USERS_MASTER_AGENT,
        component: UsersComponent,
        exact: true,
      },
    ],
  },
  {
    label: 'CONTRACT CANCELATION',
    subLink: PATHS.CONTRACT_CANCELATION,
    component: ContractCancellation,
    exact: true,
    inMenu: false,
  },
  {
    label: 'Users Detail',
    subLink: PATHS.USERS_DETAIL,
    exact: true,
    component: UsersDetailComponent,
  },
  {
    label: 'Funds',
    subLink: PATHS.PROJECTS,
    component: ProjectComponent,
    exact: true,
    inMenu: true,
    icon: PROJECT_ICON,
  },
  {
    label: 'Project Create',
    subLink: PATHS.PROJECTS_CREATE,
    component: CreateProjectComponent,
    exact: true,
  },

  {
    label: 'Project Update',
    subLink: PATHS.PROJECTS_UPDATE,
    component: CreateProjectComponent,
    exact: true,
  },

  {
    label: 'Project Detail',
    subLink: PATHS.PROJECTS_DETAIL,
    component: ProjectDetailComponent,
    exact: true,
  },
  {
    label: 'Contract Detail',
    subLink: PATHS.PROJECTS_CONTRACT_DETAIL,
    component: ProjectContractDetailComponent,
    exact: true,
  },
  {
    label: 'Deposits',
    subLink: PATHS.DEPOSITS,
    component: DepositsComponent,
    exact: true,
    inMenu: true,
    icon: WITHDRAWAL_ICON,
  },
  {
    label: 'Deposits Detail',
    subLink: PATHS.DEPOSITS_DETAIL,
    component: DepositsDetailComponent,
  },
  {
    label: 'Withdrawals',
    subLink: PATHS.WITHDRAWALS,
    component: WithdrawalsComponent,
    exact: true,
    inMenu: true,
    icon: DEPOSIT_ICON,
  },
  {
    label: 'Withdrawals Detail',
    subLink: PATHS.WITHDRAWALS_DETAIL,
    component: WithdrawalsDetailComponent,
  },
  {
    label: 'Wallet',
    subLink: PATHS.WALLET,
    component: WalletComponent,
    exact: true,
    inMenu: true,
    icon: WALLET_ICON,
  },
  {
    label: 'Setting',
    subLink: PATHS.SETTING,
    component: SettingComponent,
    exact: true,
    inMenu: true,
    icon: GEAR,
  },
  {
    label: 'Login',
    subLink: PATHS.LOGIN,
    component: LoginComponent,
    exact: true,
    inMenu: false,
    icon: ACCOUNT_ICON,
  },

  {
    subLink: '*',
    component: NotFoundComponent,
    exact: true,
  },
];

export const PublicRouter = [
  {
    label: 'Login',
    subLink: PATHS.LOGIN,
    component: LoginComponent,
    exact: true,
  },
  {
    subLink: '*',
    component: NotFoundComponent,
    exact: true,
  },
];
