export const SET_TOKEN = 'SET_TOKEN';

export const SET_LIST_USER = 'SET_LIST_USER';
export const SET_DETAIL_USER = 'SET_DETAIL_USER';

export const SET_LIST_DEPOSIT = 'SET_LIST_DEPOSIT';
export const SET_DETAIL_DEPOSIT = 'SET_DETAIL_DEPOSIT';

export const SET_LIST_WITHDRAWAL = 'SET_LIST_WITHDRAWAL';
export const SET_DETAIL_WITHDRAWAL = 'SET_DETAIL_WITHDRAWAL';

export const SET_LIST_PROJECT = 'SET_LIST_PROJECT';
export const SET_DETAIL_PROJECT = 'SET_DETAIL_PROJECT';
